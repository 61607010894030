import React, { Component } from 'react';

import './Checkbox.css';

class Checkbox extends Component {
    constructor(props){
        super(props);
        if (this.props.elements.indexOf(this.props.name) > -1){
            this.state = {
                checked: true
            };
        } else {
            this.state = {
                checked: false
            };
        }
    }
    handleEvent = (e) => {
        this.props.eventHandler(e.target.name);
        this.setState({
            checked: !this.state.checked
        });
    };
    render() {
        return (
            <div className="Checkbox">
                <label>
                    <input type="checkbox"
                           checked={this.state.checked}
                           onChange={this.handleEvent}
                           name={this.props.name}/>
                    {this.props.children}
                </label>
            </div>
        );
    }
}

export default Checkbox;

