import React, { Component } from 'react';
import {NavLink, Link} from 'react-router-dom';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars} from '@fortawesome/free-solid-svg-icons'

import './Header.css';

class Header extends Component {
    constructor(props){
        super(props);
        this.state = {
            menuOpen: false
        }
    }
    handleMenu = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    };
    render() {
        return (
            <header className={"Header " + (this.state.menuOpen ? "open": "")}>
                <Link to="/"><span className="main">Shifting Gears</span></Link>
                <div className="right">
                    <NavLink to="/physical" activeClassName="active"><span>Physical Implications</span></NavLink>
                    <NavLink to="/decisions" activeClassName="active"><span>Decisions</span></NavLink>
                    <NavLink to="/results" activeClassName="active"><span className="future">My AV Future</span></NavLink>
                </div>
                <button onClick={this.handleMenu} className="hamburger-menu">
                    <FontAwesomeIcon icon={faBars}/>
                </button>

                <div className="mobile-menu">
                    <div>
                        <Link to="/"><span onClick={this.handleMenu} className="main">Shifting Gears</span></Link>
                        <button onClick={this.handleMenu} className="hamburger-menu">
                            <FontAwesomeIcon icon={faBars}/>
                        </button>
                    </div>
                    <ul>
                        <li><NavLink to="/physical"><span onClick={this.handleMenu}>Physical Implications</span></NavLink></li>
                        <li><NavLink to="/decisions"><span onClick={this.handleMenu}>Decisions</span></NavLink></li>
                        <li><NavLink to="/results"><span onClick={this.handleMenu}>My AV Future</span></NavLink></li>
                    </ul>
                </div>
            </header>
        );
    }
}

export default Header;

