import React, { Component } from 'react';
import Checkbox from "../Checkbox";

import './Card.css';

class Card extends Component {
    render() {
        return (
            <div className="Card">
                <h4>{this.props.decisionCopy[this.props.decisionID].name}</h4>
                <p>{this.props.decisionCopy[this.props.decisionID].copy}</p>
                <div className="card-bottom">
                    <Checkbox elements={this.props.decisions}
                              name={this.props.decisionID}
                              fieldset={this.props.fieldset}
                              eventHandler={this.props.handleDecision}>
                        <div className="icon"></div>
                        <p>{this.props.decisionCopy[this.props.decisionID].subtext}</p>
                    </Checkbox>
                </div>
            </div>
        );
    }
}

export default Card;

