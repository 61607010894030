import React, { Component } from 'react';
import {Route, Switch} from 'react-router-dom';
import 'whatwg-fetch';

import Decisions from './components/Decisions';
import Implications from './components/Implications';
import Results from './components/Results';
import Home from './components/Home';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';

import './App.css';

class App extends Component {
    constructor(props){
        super(props);
        this.state = {
            decisions: {
                "one": "",
                "two": "",
                "three": "",
                "four": "",
                "five": ""
            },
            implications: [],
            decisionCopy: {
                "lower_vehicle_miles_traveled": {
                    "name": "Lower Vehicle Miles Traveled",
                    "copy": "How much will we choose to drive? Coordination among individual vehicles and increased ridesharing has the potential to increase roadway capacity by up to four times and reduce the amount of cars on the road by 70% during rush hour.",
                    "subtext": "I would MOST like to see fewer vehicles on the road and less vehicle miles traveled."
                },
                "parking_unlocks_potential" : {
                    "name": "Parking Unlocks Potential",
                    "copy": "On average, 31% of urban space is devoted to parking. AVs bring the potential to free up valuable urban space by reducing the amount of parking needed and increasing the flexibility of its location. the amount of parking space could be reduced by up to 42%, much of which could be allocated to public urban spaces such as green and/or pedestrian zones.",
                    "subtext": "I would MOST like to see fewer street parking spaces and more green and/or pedestrian zones."
                },
                "dense_and_efficient_living": {
                    "name": "Dense and Efficient Living",
                    "copy": "Where will we choose to live? A range of new mobility options and building types can make urban living more convenient and affordable. Ridesharing and on-demand transit will support ‘missing middle’ neighborhoods, leading to less sprawl and blurring the distinction between suburban and urban densities.",
                    "subtext": "I would MOST like to live in an area with lots of amenities and easy access to the city."
                },
                "complementary_to_transit": {
                    "name": "Complementary to Transit",
                    "copy": "Will AVs replace mass transit? AVs have the potential to complement other modes of travel — rail, bus, cycling and walking — and fill in gaps where other modes are less efficient. A positive example can be found in Singapore, where AV technology is primarily seen as a last-mile solution to support a robust transit network in a dense city.",
                    "subtext": "I would MOST like to see AVs as a complement to transit options."
                },
                "mixed_environment": {
                    "name": "Mixed Environment: AVs + People",
                    "copy": "How will autonomous vehicles share the right of way with cyclists and pedestrians? Improved safety and the potential to achieve ‘vision zero’ is a significant opportunity that all stakeholders seem to agree upon. There are diverging opinions, however, on whether this will involve segregation or mixing of modes. The ability of AV technology to quickly become sophisticated enough to navigate complex environments combined with intelligent regulation and design will be key to finding the right balance. NACTO envisions a world where driverless cars are limited to 25 mph on city streets and integrated into the dense, complex environments where people are increasingly choosing to live.",
                    "subtext": "I would MOST like to see AVs as a complement to people, with human-centered design as a priority."
                },
                "autonomous_rapid_transit": {
                    "name": "Autonomous Rapid Transit",
                    "copy": "AV technology evolves in tandem with roadways and other supporting infrastructure, utilizing combined public and privately owned fleets to create a shared experience for all.",
                    "subtext": "I would MOST like to see AVs as a public-private shared amenity."
                },
                "car_sharing": {
                    "name": "Car Sharing",
                    "copy": "AV technology evolves in tandem with roadways and other supporting infrastructure, utilizing combined public and privately owned on-demand AVs in a similar fashion to today’s ride-hailing pool options.",
                    "subtext": "I would MOST like to see shared on-demand AVs."
                },
                "fleets": {
                    "name": "Fleets",
                    "copy": "Car manufacturers are already testing the idea of partnering with ride-hailing companies to create fleets of privately-owned on-demand AVs.",
                    "subtext": "I would MOST like to see privately-owned, shared on-demand AVs"
                },
                "av_single_taxis": {
                    "name": "AV Single Taxis",
                    "copy": "These privately-owned, on-demand AVs would service individuals as taxis and ride-hailing companies do.",
                    "subtext": "I would MOST like to see privately-owned, on-demand AVs for a single user or entity."
                },
                "personal_avs": {
                    "name": "Personal AVs",
                    "copy": "Individually owned AVs will provide a significant convenience for owners, but will not significantly abet the current state of traffic, emissions, parking, or VMTs.",
                    "subtext": "I would MOST like to see privately-owned AVs."
                },
                "coordinated_partnerships": {
                    "name": "Coordinated Partnerships",
                    "copy": "Who will be responsible for constructing roads and other major infrastructure? Infrastructure should continue to expand with predominant funding coming from local, state, and federal sources but developed using a method to accommodate AVs. Tech companies such as Google, Nest, and Fiber should forge partnerships with State DOT’s to manage and develop road systems that are suited to their AVs.",
                    "subtext": "I would MOST like to see coordinated partnership between public and private sectors to move AV infrastructure forward. "
                },
                "innovative_roadways": {
                    "name": "Innovative Roadways",
                    "copy": "How will AV technology affect ROW dimensions? Will we need on-street parking? The efficiency of shared AVs allows for more flexible streets with fewer lanes and new curbside uses. The residual right of way should be reclaimed for public space or new development, leading to healthier, safer, and more affordable cities.",
                    "subtext": "I would MOST like to see innovative approaches to roadways as cities integrate AVs, transit, cyclists, and pedestrians."
                },
                "reclaiming_the_street": {
                    "name": "Reclaiming the Street",
                    "copy": "Will more efficient use of our travel lanes create more space for landscapes and people? As AVs become mainstream and traditional retail is transformed by new distribution chains, streets should be valued more than ever as civic spaces for community and recreation.",
                    "subtext": "I would MOST like to see human-centered design prioritized for reclaimed urban spaces."
                },
                "safety": {
                    "name": "Safety",
                    "copy": "AV technology should evolve in tandem with roadways and other supporting infrastructure, creating safer streets and improving the quality of the public realm.",
                    "subtext": "We should MOST prioritize safety as we design and build urban infrastructure that supports AVs"
                },
                "access": {
                    "name": "Access",
                    "copy": "Shared ownership platforms should be developed in tandem with the public transit system so that all can benefit from increased mobility.",
                    "subtext": "We should MOST prioritize access and accessibility as we design and build urban infrastructure that supports AVs."
                },
                "jobs": {
                    "name": "Jobs",
                    "copy": "Any jobs in danger of being replaced by AVs should be reinvented or redistributed through government initiatives or new innovative approaches by the affected industries.",
                    "subtext": "We should MOST prioritize supporting options for those whose jobs are at risk."
                },
                "data": {
                    "name": "Data",
                    "copy": "Open access to comprehensive data on VMTs and routes travelled should lead to more efficient transportation planning and operations.",
                    "subtext": "We should MOST prioritize efficient transportation planning and operations."
                },
            },
            implicationCopy: {
                "highways": {
                    "name": "Highways",
                    "copy": "Highways and other limited access routes may be the first places where AVs can drive, creating more efficient regional commutes and freeing up complex intersections for open space and redevelopment.",
                    "location": "Region + City",
                    "coords": [744, 481],
                    "icon": "1",
                    "tag": "Land Use & Urban Transformation"
                },
                "rail_and_transit_hubs": {
                    "name": "Rail and Transit Hubs",
                    "copy": "AVs could function as seamless last mile solutions for regional rail services, strengthening the demand for existing rail lines",
                    "location": "Region + City",
                    "coords": [407, 662],
                    "icon": "2",
                    "tag": "Multimodal Integration"
                },
                "arterial_roads": {
                    "name": "Arterial Roads",
                    "copy": "Some arterial roads may be converted into efficient AV-only routes, allowing space for trails and open spaces",
                    "location": "Region + City",
                    "coords": [685, 314],
                    "icon": "3",
                    "tag": "Public Realm"
                },
                "urban_arterial_roads": {
                    "name": "Arterial Roads",
                    "copy": "AVs could eliminate many negative impacts of major arterials. Traffic signals, speed bumps and guard rails could be eliminated, and more space created for people.",
                    "location": "Urban Neighborhood",
                    "coords": [675, 250],
                    "icon": "1",
                    "tag": "Public Realm"
                },
                "housing": {
                    "name": "Housing Affordability",
                    "copy": "AV shuttles have a high potential to increase the stock of housing connected to city and regional transit networks, ",
                    "location": "Urban Neighborhood",
                    "coords": [1000, 120],
                    "icon": "2",
                    "tag": "Social Impact and Equity"
                },
                "commercial_centers": {
                    "name": "Commercial Centers",
                    "copy": "Commercial streets, where short-term parking often competes with space for bikes and pedestrians could be transformed by new curbside uses and safer pedestrian crossings",
                    "location": "Urban Neighborhood",
                    "coords": [1000, 380],
                    "icon": "3",
                    "tag": "Public Realm"
                },
                "transit_stations": {
                    "name": "Transit Stations",
                    "copy": "AV platforms could serve as a last mile connection, expanding access to regional rail and transforming the design of stations.",
                    "location": "Urban Neighborhood",
                    "coords": [1000, 460],
                    "icon": "4",
                    "tag": "Multimodal Integration"
                },
                "parking": {
                    "name": "Infill Development",
                    "copy": "Opportunities to reduce surface and structured parking could free up valuable sites for new development and open spaces.",
                    "location": "Urban Neighborhood",
                    "coords": [950, 465],
                    "icon": "5",
                    "tag": "Land Use & Urban Transformation"
                },
                "campus_connections": {
                    "name": "Campus Connections",
                    "copy": "Classes might begin when students step into a mobile classroom to go to campus, allowing more efficient academic scheduling and space allocation",
                    "location": "Campus",
                    "coords": [545, 360],
                    "icon": "1",
                    "tag": "Multimodal Integration"
                },
                "campus_parking_areas": {
                    "name": "Parking Areas",
                    "copy": "Reduced parking requirements could unlock valuable infill opportunities for academic and student life.",
                    "location": "Campus",
                    "coords": [825, 250],
                    "icon": "2",
                    "tag": "Land Use & Urban Transformation"
                },
                "loading_areas": {
                    "name": "Service Areas",
                    "copy": "Smaller autonomous delivery vehicles and the ability to schedule more deliveries at night allow may allow service areas to be activated as amenities and open space.",
                    "location": "Campus",
                    "coords": [1050, 290],
                    "icon": "3",
                    "tag": "Public Realm"
                },
                "recreation": {
                    "name": "Recreation",
                    "copy": "Tailgating before a big game might disappear without privately owned cars (or maybe not).",
                    "location": "Campus",
                    "coords": [580, 620],
                    "icon": "4",
                    "tag": "Ownership Models"
                },
                "roadways": {
                    "name": "Roadways",
                    "copy": "The amount and scale of on-campus roadways could be reduced, creating more space for iconic and recreational open spaces.",
                    "location": "Campus",
                    "coords": [1050, 480],
                    "icon": "5",
                    "tag": "Public Realm"
                },
                "historic_downtown": {
                    "name": "Historic Downtown",
                    "copy": "Landmarks and historic squares that are isolated by confusing intersections can become connected public spaces.",
                    "location": "Downtown",
                    "coords": [670, 220],
                    "icon": "1",
                    "tag": "Public Realm"
                },
                "historic_street_network": {
                    "name": "Historic Street Network",
                    "copy": "Narrow historic streets designed before automobiles could be pedestrianized and incorporated into dynamic superblocks according to demand and events.",
                    "location": "Downtown",
                    "coords": [785, 215],
                    "icon": "2",
                    "tag": "Public Realm"
                },
                "infrastructure_network": {
                    "name": "Infrastructure Network",
                    "copy": "Car-oriented infrastructure such as highway interchanges and bridges free up space for infill opportunities.",
                    "location": "Downtown",
                    "coords": [873, 409],
                    "icon": "3",
                    "tag": "Land Use & Urban Transformation"
                },
                "curbside_parking": {
                    "name": "Curbside Parking",
                    "copy": "Curbside parking could be replaced with public spaces. Dynamic pick-up and dropoff zones could chase major events around the city",
                    "location": "Downtown",
                    "coords": [580, 467],
                    "icon": "4",
                    "tag": "Multimodal Integration"
                },
                "structured_parking": {
                    "name": "Structured Parking",
                    "copy": "Podium and underground parking in existing buildings could be converted to usable space, or replaced with innovative new building types that are not constrained by the need to provide parking.",
                    "location": "Downtown",
                    "coords": [517, 472],
                    "icon": "5",
                    "tag": "Land Use & Urban Transformation"
                },
                "transit_hubs": {
                    "name": "Transit Hubs",
                    "copy": "Multimodal transit hubs could be designed to coordinate a range of transportation options, from autonomous minibuses to regional rail.",
                    "location": "Downtown",
                    "coords": [662, 497],
                    "icon": "6",
                    "tag": "Multimodal Integration"
                },
                "airport_parking_areas": {
                    "name": "Revenue Sharing",
                    "copy": "As parking, a major revenue source for airports, becomes unnecessary, airports may seek new partnerships with rental cars and fleet operators.",
                    "location": "Special Typologies",
                    "coords": [870, 276],
                    "icon": "1",
                    "tag": "Ownership Models"
                },
                "rental_car_center": {
                    "name": "Airport Fleets",
                    "copy": "Airports may prioritize their own fleets of vehicles and shuttles to create more efficient connections to cities and suburbs.",
                    "location": "Special Typologies",
                    "coords": [1007, 228],
                    "icon": "2",
                    "tag": "Ownership Models"
                },
                "dropoff_pickup_area": {
                    "name": "Drop-Off / Pick-Up Area",
                    "copy": "Airport drop-off becomes simpler and more coordinated",
                    "location": "Special Typologies",
                    "coords": [902, 421],
                    "icon": "3"
                },
                "waterfronts": {
                    "name": "Urban Transformations",
                    "copy": "Reducing the footprint of industrial areas could facilitate the transformation of waterfronts, railyards, and other key places into open spaces and urban neighborhoods",
                    "location": "Special Typologies",
                    "coords": [256, 172],
                    "icon": "4",
                    "tag": "Land Use & Urban Transformation"
                },
                "warehouse_logistics_center": {
                    "name": "Warehouse & Logistics Center",
                    "copy": "Logistic centers could become more compact and efficient due to 24-hour scheduling, reducing the space needed for parking and narrower turning radius.",
                    "location": "Special Typologies",
                    "coords": [407, 358],
                    "icon": "5",
                    "tag": "Multimodal Integration"
                },
                "sprawling_subdivisions": {
                    "name": "Sprawling Subdivisions",
                    "copy": "The convenience of AVs could encourage greater development of low density subdivisions and neighborhoods streets that prioritize vehicles.",
                    "location": "Suburbs",
                    "coords": [449, 148],
                    "icon": "1",
                    "tag": "Land Use & Urban Transformation"
                },
                "schools": {
                    "name": "Schools",
                    "copy": "AVs could bring greater freedom of mobility to students too young to drive and reduce the need for drop-off and parking areas",
                    "location": "Suburbs",
                    "coords": [809, 144],
                    "icon": "2",
                    "tag": "Social Impact and Equity"
                },
                "vehicle_storage_areas": {
                    "name": "Vehicle Storage Areas",
                    "copy": "As parking is eliminated in more urban areas and individual properties, regional AV hubs for storage and maintenance could be located in suburban areas. ",
                    "location": "Suburbs",
                    "coords": [1140, 205],
                    "icon": "3",
                    "tag": "Land Use & Urban Transformation"
                },
                "commuter_rail_stations": {
                    "name": "Commuter Rail Stations",
                    "copy": "AVs could serve as seamless last mile solutions, boosting commuter rail ridership and allowing for smaller stations with reduced parking.",
                    "location": "Suburbs",
                    "coords": [1160, 255],
                    "icon": "4",
                    "tag": "Multimodal Integration"
                },
                "malls_and_bigbox_retail": {
                    "name": "Malls and Big-Box Retail",
                    "copy": "The vast seas of surface parking around suburban retail could be replaced by infill development and open space, allowing for more sustainable stormwater management. As online shopping and other retail innovations become more commonplace, suburban shopping centers may become places for entertainment and recreation, or even evolve into town centers.",
                    "location": "Suburbs",
                    "coords": [667, 460],
                    "icon": "5",
                    "tag": "Land Use & Urban Transformation"
                }
            },
            uuid: this.uuid(),
            count: {
                "highways": 0,
                "rail_and_transit_hubs": 0,
                "arterial_roads": 0,
                "urban_arterial_roads": 0,
                "housing": 0,
                "commercial_centers": 0,
                "transit_stations": 0,
                "parking": 0,
                "campus_connections": 0,
                "campus_parking_areas": 0,
                "loading_areas": 0,
                "recreation": 0,
                "roadways": 0,
                "historic_downtown": 0,
                "historic_street_network": 0,
                "infrastructure_network": 0,
                "curbside_parking": 0,
                "structured_parking": 0,
                "transit_hubs": 0,
                "airport_parking_areas": 0,
                "rental_car_center": 0,
                "dropoff_pickup_area": 0,
                "waterfronts": 0,
                "warehouse_logistics_center": 0,
                "sprawling_subdivisions": 0,
                "lower_vehicle_miles_traveled": 0,
                "parking_unlocks_potential": 0,
                "dense_and_efficient_living": 0,
                "complementary_to_transit": 0,
                "mixed_environment": 0,
                "autonomous_rapid_transit": 0,
                "car_sharing": 0,
                "fleets": 0,
                "av_single_taxis": 0,
                "personal_avs": 0,
                "coordinated_partnerships": 0,
                "innovative_roadways": 0,
                "reclaiming_the_street": 0,
                "safety": 0,
                "access": 0,
                "jobs": 0,
                "data": 0,
                "schools": 0,
                "vehicle_storage_areas": 0,
                "commuter_rail_stations": 0,
                "malls_and_bigbox_retail": 0
            }
        };
    }
    uuid = () => {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    };
    postData = (count) => {
        fetch(`https://5ug3mjq422.execute-api.us-east-1.amazonaws.com/dev/user-input/${this.state.uuid}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "collectibles": count
            })
        }).then(function(response) {
            if (!response.ok) console.log(response.statusText);
            return response;
        }).catch(function(error) {
            console.log(error);
        });
    };
    handleDecision = (name, index) => {
        let newDecisions = Object.assign({}, this.state.decisions);
        newDecisions[index] = name;
        this.setState({
            decisions: newDecisions
        }, this.updateCount);
    };
    handleImplication = (name) => {
        let elIndex = this.state.implications.indexOf(name);
        let newImplication = this.state.implications.slice();
        if (elIndex === -1){
            newImplication.push(name);
        } else {
            newImplication.splice(elIndex, 1);
        }
        this.setState({
            implications: newImplication
        }, this.updateCount);
    };
    updateCount = () => {
        let newCount = Object.assign({}, this.state.count);
        this.state.implications.map(implication => newCount[implication] = 1);

        Object.keys(this.state.decisions).map(decision => {
            if (this.state.decisions[decision].length > 0){
                newCount[this.state.decisions[decision]] = 1;
            }
        });
        this.postData(newCount);
    };
    render() {
        return (
          <div className="App">
            <div className="background-image">

            </div>
            <Header/>
            <Sidebar
                decisions={this.state.decisions}
                implications={this.state.implications}
                implicationCopy={this.state.implicationCopy}
                decisionCopy={this.state.decisionCopy}
            />

            <Switch>
              <Route exact path="/" component={Home}/>
              <Route exact path="/decisions" render={() => {return <Decisions
                                                                      decisions={this.state.decisions}
                                                                      decisionCopy={this.state.decisionCopy}
                                                                      handleDecision={this.handleDecision} />}} />
              <Route exact path="/physical" render={() => {return <Implications
                                                                      implications={this.state.implications}
                                                                      implicationCopy={this.state.implicationCopy}
                                                                      handleImplication={this.handleImplication} />}} />
              <Route exact path="/results" render={() => {return <Results
                                                                    decisions={this.state.decisions}
                                                                    implications={this.state.implications}
                                                                    implicationCopy={this.state.implicationCopy}
                                                                    decisionCopy={this.state.decisionCopy} />}} />
            </Switch>

            <Footer/>
          </div>
        );
    }
}

export default App;
