import React, { Component } from 'react';
import Slider from "react-slick";
import ReactCompareImage from 'react-compare-image';
import Sticky from 'react-stickynode';

import Map from './components/Map';

import campusDiagram from './../../assets/diagrams/campus.jpg';
import downtownDiagram from './../../assets/diagrams/downtown.jpg';
import regionalDiagram from './../../assets/diagrams/regional.jpg';
import specialDiagram from './../../assets/diagrams/special.jpg';
import suburbsDiagram from './../../assets/diagrams/suburbs.jpg';
import urbanDiagram from './../../assets/diagrams/urban_neighborhood.jpg';

import regional1 from './../../assets/locations/regional/Regional_1.jpg';
import regional2 from './../../assets/locations/regional/Regional_2.jpg';
import regional3 from './../../assets/locations/regional/Regional_3.jpg';

import downtownBefore from './../../assets/locations/downtown/Downtown_Before.png';
import downtownAfter from './../../assets/locations/downtown/Downtown_After.png';

import urbanBefore from './../../assets/locations/urban/Urban Neighborhood_Chunk_Before.png';
import urbanAfter from './../../assets/locations/urban/Urban Neighborhood_Chunk_After.png';

import suburbanBefore from './../../assets/locations/suburbs/Suburban_Before.png';
import suburbanAfter from './../../assets/locations/suburbs/Suburban_After.png';

import campusBefore from './../../assets/locations/campus/Campus_Before.png';
import campusAfter from './../../assets/locations/campus/Campus_After.png';

import specialAfter from './../../assets/locations/special/Special_Before.png';
import specialBefore from './../../assets/locations/special/Special_After.png';

import './Implications.css';
import {Link} from "react-router-dom";


class Implications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: []
        };
        this.filterCopy = [
            {
                "name": "Land Use & Urban Transformation",
                "copy": "Land Use & Urban Transformation"
            },
            {
                "name": "Multimodal Integration",
                "copy": "Multimodal Integration"
            },
            {
                "name": "Public Realm",
                "copy": "The Public Realm"
            },
            {
                "name": "Ownership Models",
                "copy": "Ownership Models"
            },
            {
                "name": "Social Impact and Equity",
                "copy": "Social Impact and Equity"
            }
        ]
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    handleImplication = (name) => {
        this.props.handleImplication(name);
    };
    handleFilter = (e, filter) => {
        e.preventDefault();
        let filters = this.state.filters.slice();
        if (this.state.filters.indexOf(filter) > -1){
            filters.splice(this.state.filters.indexOf(filter), 1);
        } else {
            filters.push(filter);
        }
        this.setState({
            filters: filters
        });
    };
    render() {
        let sliderSettings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        return (
            <div className="Implications">
                <div className="container">
                    <div className="row">
                        <div className="col-1">
                        </div>
                        <div className="hero col-10 align-self-center">
                            <h3 className="highlight">Physical Implications</h3>
                            <h2>Autonomous vehicles will impact the physical shape of our daily lives. Explore the different ways below.</h2>
                        </div>
                        <div className="col-1">
                        </div>
                    </div>
                </div>

                <Sticky
                    top='.Header'
                    innerZ={998}>
                    <div className="highlight-header mobile">
                            <p className="header">Highlight ideas related to:</p>
                            <div className="scroll">
                                <ul className="list-inline">
                                    {this.filterCopy.map(filter => {
                                        return <li key={filter.name} className="list-inline-item">
                                            <button className={"filter" + ((this.state.filters.indexOf(filter.name) > -1) ? " active" : "")} onClick={(e) => this.handleFilter(e, filter.name)}>{filter.copy}</button>
                                        </li>
                                    })}
                                </ul>
                            </div>

                    </div>
                </Sticky>

                <Sticky
                    top='.Header'
                    innerZ={998}>
                    <div className="highlight-header">
                        <div className="container">
                            <div className="row justify-content-center">
                                <ul className="list-inline">
                                    <li className="list-inline-item">Highlight ideas related to:</li>
                                    {this.filterCopy.map(filter => {
                                        return <li key={filter.name} className="list-inline-item">
                                            <button className={"filter" + ((this.state.filters.indexOf(filter.name) > -1) ? " active" : "")} onClick={(e) => this.handleFilter(e, filter.name)}>{filter.copy}</button>
                                        </li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Sticky>

                <div className="content region">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h2>Regional + City</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-10 col-sm-12">
                                <h3>In many contemporary cities, highways and long commutes define regional connectivity. AVs have the potential to strengthen regional transit systems, scale back the need for highway infrastructure, and make regional commuting patterns more efficient.</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-10 col-sm-12">
                                <h3 className="callout">What's most important to you?</h3>
                            </div>
                        </div>
                    </div>
                    <div className="diagram">
                        <Map
                            imageOverlay={regionalDiagram}
                            imageDimensions={[925,1400]}
                            filters={this.state.filters}
                            implications={this.props.implications}
                            implicationCopy={this.props.implicationCopy}
                            handleImplication={this.handleImplication}
                            markers={Object.keys(this.props.implicationCopy).filter(key => this.props.implicationCopy[key].location === "Region + City")}
                        />
                    </div>
                </div>

                <div className="interstitial-img">
                    <Slider {...sliderSettings}>
                        <img src={regional1} alt="Regional" />
                        <img src={regional2} alt="Regional" />
                        <img src={regional3} alt="Regional" />
                    </Slider>
                </div>
                
                <div className="content downtown">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h2>Downtown</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-10 col-sm-12">
                                <h3>In downtown areas, often the
                                    densest areas in their regions,
                                    AVs may allow cities to use
                                    valuable space more efficiently. </h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-10 col-sm-12">
                                <h3 className="callout">What's most important to you?</h3>
                            </div>
                        </div>
                    </div>
                    <div className="diagram">
                        <Map
                            imageOverlay={downtownDiagram}
                            imageDimensions={[706,1400]}
                            filters={this.state.filters}
                            implications={this.props.implications}
                            implicationCopy={this.props.implicationCopy}
                            handleImplication={this.handleImplication}
                            markers={Object.keys(this.props.implicationCopy).filter(key => this.props.implicationCopy[key].location === "Downtown")}
                        />
                    </div>
                </div>

                <div className="compare">
                    <ReactCompareImage sliderLineWidth={5} handleSize={40} leftImage={downtownBefore} rightImage={downtownAfter} />
                </div>

                <div className="content urban">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h2>Urban Neighborhood</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-10 col-sm-12">
                                <h3>AVs may have the most impact in a
                                    diverse range of urban neighborhoods
                                    that aren’t quite dense enough to
                                    support mass transit.</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-10 col-sm-12">
                                <h3 className="callout">What's most important to you?</h3>
                            </div>
                        </div>
                    </div>
                    <div className="diagram">
                        <Map
                            imageOverlay={urbanDiagram}
                            imageDimensions={[645,1400]}
                            filters={this.state.filters}
                            implications={this.props.implications}
                            handleImplication={this.handleImplication}
                            implicationCopy={this.props.implicationCopy}
                            markers={Object.keys(this.props.implicationCopy).filter(key => this.props.implicationCopy[key].location === "Urban Neighborhood")}
                        />
                    </div>
                </div>

                <div className="compare">
                    <ReactCompareImage sliderLineWidth={5} handleSize={40} leftImage={urbanBefore} rightImage={urbanAfter} />
                </div>

                <div className="content suburbs">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h2>Suburb</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-10 col-sm-12">
                                <h3>AVs bring the potential to create
                                    more compact and connected
                                    suburbs. However, there is a
                                    very real possibility that the
                                    convenience of AVs could
                                    incentivize low density sprawl.</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-10 col-sm-12">
                                <h3 className="callout">What's most important to you?</h3>
                            </div>
                        </div>
                    </div>
                    <div className="diagram">
                        <Map
                            imageOverlay={suburbsDiagram}
                            imageDimensions={[600,1400]}
                            filters={this.state.filters}
                            implications={this.props.implications}
                            handleImplication={this.handleImplication}
                            implicationCopy={this.props.implicationCopy}
                            markers={Object.keys(this.props.implicationCopy).filter(key => this.props.implicationCopy[key].location === "Suburbs")}
                        />
                    </div>
                </div>

                <div className="compare">
                    <ReactCompareImage sliderLineWidth={5} handleSize={40} leftImage={suburbanBefore} rightImage={suburbanAfter} />
                </div>

                <div className="content campus">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h2>Campus</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-10 col-sm-12">
                                <h3>The impact of AVs could
                                    complement the emphasis on
                                    communal living, the priority of
                                    landscapes over roadways, and
                                    the need for more academic
                                    space within a finite campus
                                    area.</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-10 col-sm-12">
                                <h3 className="callout">What's most important to you?</h3>
                            </div>
                        </div>
                    </div>
                    <div className="diagram">
                        <Map
                            imageOverlay={campusDiagram}
                            imageDimensions={[873,1400]}
                            filters={this.state.filters}
                            implications={this.props.implications}
                            handleImplication={this.handleImplication}
                            implicationCopy={this.props.implicationCopy}
                            markers={Object.keys(this.props.implicationCopy).filter(key => this.props.implicationCopy[key].location === "Campus")}
                        />
                    </div>
                </div>

                <div className="compare">
                    <ReactCompareImage sliderLineWidth={5} handleSize={40} leftImage={campusBefore} rightImage={campusAfter} />
                </div>

                <div className="content special">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h2>Special Typologies</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-10 col-sm-12">
                                <h3 className="header">Airports</h3>
                                <h3>AVs could help to significantly improve access to airports by transforming parking and ground transportation.</h3>

                                <h3 className="header">Industrial</h3>
                                <h3>Industrial zones could better coexist with other types of areas in the urban fabric through the reduction of negative impacts from freights and logistics.</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-10 col-sm-12">
                                <h3 className="callout">What improvements would you like to see?</h3>
                            </div>
                        </div>
                    </div>
                    <div className="diagram">
                        <Map
                            imageOverlay={specialDiagram}
                            imageDimensions={[600,1400]}
                            filters={this.state.filters}
                            implications={this.props.implications}
                            implicationCopy={this.props.implicationCopy}
                            handleImplication={this.handleImplication}
                            markers={Object.keys(this.props.implicationCopy).filter(key => this.props.implicationCopy[key].location === "Special Typologies")}
                        />
                    </div>
                </div>

                <div className="compare">
                    <ReactCompareImage sliderLineWidth={5} handleSize={40} leftImage={specialAfter} rightImage={specialBefore} />
                </div>

                <section className="dark last">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-12 text-center mx-auto">
                                <h2>How can we get there?

                                </h2>
                                <h3>Examine the big decisions that we must face to ensure the best implementation of AV technology for everyone. Vote on how you think we should collectively handle these choices.</h3>

                                <Link to="/decisions">
                                    <div className="button">
                                        Explore Big Decisions
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Implications;


