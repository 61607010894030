/*global FB*/

import React, { Component } from 'react';
import {Helmet} from "react-helmet";

import facebook from './../../assets/facebook-black.svg';
import twitter from './../../assets/twitter-black.svg';

import heartPink from './../../assets/heart-pink.svg';
import heartGreen from './../../assets/heart-green.svg';

import thumbPink from './../../assets/thumb-pink-results.svg';
import thumbGreen from './../../assets/thumb-green-results.svg';

import arrowDown from './../../assets/arrow-down.png';
import arrowUp from './../../assets/arrow-up.png';


import './Results.css';

class Results extends Component {
    constructor(props){
        super(props);
        this.state = {
            count: null,
            expandImplications: false,
            expandDecisions: false
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        fetch(`https://5ug3mjq422.execute-api.us-east-1.amazonaws.com/dev/get-all`)
            .then(response => response.json())
            .then((data) =>  {
                this.setState({ count: data });
            }).catch(function(error) {
                console.log(error);
            });

        window.fbAsyncInit = function() {
            //SDK loaded, initialize it
            FB.init({
                appId      : '1968846319831631',
                xfbml      : true,
                version    : 'v2.6'
            });
            //JS SDK initialized, now you can use it
            FB.XFBML.parse();
        };

        //load the JavaScript SDK
        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }
    shareFacebook = () => {
        FB.ui({
            method: 'share',
            display: 'popup',
            href: 'http://projects.staging.sasaki.com/av-site/#/results',
        }, function(response){});
    };
    handleView = (type) => {
        console.log(type);
        this.setState({
           [type]: !this.state[type]
        });
    };
    render() {
        let numberOfDecisions = 0;
        let randDecisions = [];
        let allDecisions = [];

        let decisionsText = "";
        let implicationsText = "";

        Object.keys(this.props.decisions).map(key => {
            if (this.props.decisions[key]){
                numberOfDecisions++;
            }
        });

        if (numberOfDecisions > 0){
            let keys = [];
            for (let prop in this.props.decisions) {
                if (this.props.decisions.hasOwnProperty(prop) && this.props.decisions[prop].length > 0) {
                    allDecisions.push(this.props.decisions[prop]);
                    keys.push(prop);
                }
            }
            do {
                randDecisions[randDecisions.length] = keys.splice(
                    Math.floor(Math.random() * keys.length)
                    , 1)[0];
            } while (randDecisions.length < (numberOfDecisions > 2 ? 3 : numberOfDecisions));

            randDecisions.map((decision, index) => {
                if (randDecisions.length === 1){
                    decisionsText += this.props.decisionCopy[this.props.decisions[decision]].name + " ";
                } else if (index === randDecisions.length - 1){
                    decisionsText += "and " + this.props.decisionCopy[this.props.decisions[decision]].name + " ";
                } else {
                    decisionsText += this.props.decisionCopy[this.props.decisions[decision]].name + ", ";
                }
            })
        }

        let names = [];
        let greatestLocation = "";
        if (this.props.implications.length > 0){
            this.props.implications.map((implication) => {
                names[this.props.implicationCopy[implication].location] = (names[this.props.implicationCopy[implication].location] || []);
                names[this.props.implicationCopy[implication].location].push(this.props.implicationCopy[implication].name);

            });
            greatestLocation = Object.keys(names).reduce(function(prev, current) {
                if (prev.length > current.length) return prev;
                else return current
            });

            names[greatestLocation].map((implication, index) => {
                if (index === 0 && names[greatestLocation].length > 1) {
                    implicationsText += implication + " and ";
                } else if (index === 0) {
                    implicationsText += implication + " ";
                } else if (index === 1) {
                    implicationsText += implication + " ";
                }
            })
        }
        return (
            <div className="Results">

                <Helmet>
                    <meta property="og:description"   content={"I thought " + decisionsText + "should be prioritized. " + "I would like to see " + implicationsText + "in my " + greatestLocation + ". What's your av future?"} />
                    <meta name="twitter:description"  content={"I thought " + decisionsText + "should be prioritized. " + "I would like to see " + implicationsText + "in my " + greatestLocation + ". What's your av future?"} />
                </Helmet>


                <div className="container">
                    <div className="row">
                        <div className="col-1">
                        </div>

                        <div className="hero empty col-10 align-self-center">
                            <h3 className="highlight">MY AV FUTURE</h3>
                            {!numberOfDecisions && !this.props.implications.length &&
                                <h2 className="cta">Select items throughout the site to have your AV future story appear here!</h2>
                            }

                            {numberOfDecisions > 0 &&
                                <span className="madlib">
                                    <span>{decisionsText} should be prioritized.</span>
                                </span>
                            }

                            {this.props.implications.length > 0 &&
                                <span className="madlib">
                                    <span>
                                        I would like to see {implicationsText} in my {greatestLocation}.
                                    </span>
                                </span>
                            }
                        </div>

                        <div className="col-1">
                        </div>
                    </div>
                </div>
                <section className="green">
                <div className="container">
                    <div className="row">
                        <div className="col text-center align-self-center">
                            <h2>Share your AV Future</h2>
                            <p>Tell your network about your idea AV future -- <br/> and see what others imagine too!</p>
                            <ul className="social list-inline">
                                <li className="list-inline-item"><img onClick={this.shareFacebook} src={facebook} alt="Facebook"/></li>
                                <li className="list-inline-item">
                                    {this.props.implications.length > 0 && numberOfDecisions > 0 &&
                                    <a target="_blank"
                                        href={"http://www.twitter.com/intent/tweet?url=http://shiftinggears.sasaki.com&text=" +
                                        decisionsText + "should be prioritized. " +
                                        "I would like to see " + implicationsText + "in m " + greatestLocation + "."}>
                                        <img src={twitter} alt="Twitter"/>
                                    </a>}
                                    {!this.props.implications.length && !numberOfDecisions &&
                                    <a target="_blank"
                                       href={"http://www.twitter.com/intent/tweet?url=http://shiftinggears.sasaki.com&text=Check out your AV Future!"}>
                                        <img src={twitter} alt="Twitter"/>
                                    </a>}
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <h4 className="highlight">Most-supported</h4>
                                <h2>Decision Factors</h2>
                                <p className="subtext">Your selections are shown below in <span className="highlight">pink</span>.</p>
                                <table className="results">
                                    <tbody>
                                    {Object.keys(this.props.decisionCopy).sort((a, b)=>{ if (!this.state.count) return; return this.state.count[b] - this.state.count[a]}).map((key, index) =>
                                        <tr key={key} className={(index > 9 && !this.state.expandDecisions) ? "hidden": ""}>
                                            <td className="count">{(this.state.count && this.state.count[key]) ? this.state.count[key] : 0}</td>
                                            <td className="thumbs">
                                                {allDecisions.indexOf(key) > -1 && <img alt="" src={thumbPink} />}
                                                {allDecisions.indexOf(key) === -1 && <img alt="" src={thumbGreen} />}
                                            </td>
                                            <td>{this.props.decisionCopy[key].name}</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td></td>
                                        <td colSpan={2}>
                                            <div className={"viewAll " + ((this.state.expandDecisions) ? "open" : "closed")}
                                                 onClick={() => this.handleView("expandDecisions")}>
                                                {this.state.expandDecisions &&
                                                <span><img src={arrowUp}/>Close</span>}
                                                {!this.state.expandDecisions &&
                                                <span><img src={arrowDown}/>View All</span>}
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <h4 className="highlight">Most-liked</h4>
                                <h2>Physical Environment Ideas</h2>
                                <p className="subtext">Your selections are shown below in <span className="highlight">pink</span>.</p>
                                <table className="results">
                                    <tbody>
                                    {Object.keys(this.props.implicationCopy).sort((a, b)=>{ if (!this.state.count) return; return this.state.count[b] - this.state.count[a]}).map((key, index) =>
                                        <tr key={key} className={(index > 9 && !this.state.expandImplications) ? "hidden": ""}>
                                            <td className="count">{(this.state.count && this.state.count[key]) ? this.state.count[key] : 0}</td>
                                            <td className="hearts">
                                                {this.props.implications.indexOf(key) > -1 && <img alt="" src={heartPink} />}
                                                {this.props.implications.indexOf(key) === -1 && <img alt="" src={heartGreen} />}
                                            </td>
                                            <td>{this.props.implicationCopy[key].name}</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td></td>
                                        <td colSpan={2}>
                                            <div className={"viewAll " + ((this.state.expandImplications) ? "open" : "closed")}
                                                 onClick={() => this.handleView("expandImplications")}>
                                                {this.state.expandImplications &&
                                                    <span><img src={arrowUp}/>Close</span>}
                                                {!this.state.expandImplications &&
                                                    <span><img src={arrowDown}/>View All</span>}
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Results;

