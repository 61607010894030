import React, { Component } from 'react';
import {Link} from "react-router-dom";

import Card from './components/Card';
import Checkbox from './components/Checkbox';

import './Decisions.css';

class Decisions extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div className="Decisions">
                <div className="container">
                    <div className="row">
                        <div className="col-1">
                        </div>
                        <div className="hero col-10 align-self-center">
                            <h3 className="highlight">Big Decisions</h3>
                            <h2>We are collectively poised at a moment of opportunity to make big decisions now that will drive the future of our cities. ​​​​​​​</h2>
                        </div>
                        <div className="col-1">
                        </div>
                    </div>
                </div>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-12">
                                <h2><span className="highlight">#1 </span>Land Use and Urban Transformation​​​​​​​</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 col-sm-12">
                                <h3>The mass production of the automobile led to an era of regional travel, urban expansion, and land-use transformation at unprecedented rates. How will ubiquitous AVs affect future development trends?</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="card-list">

                                      <Card
                                          fieldset={"one"}
                                          decisionID={"lower_vehicle_miles_traveled"}
                                          handleDecision={this.props.handleDecision}
                                          decisionCopy={this.props.decisionCopy}
                                          decisions={this.props.decisions}
                                      />


                                    <Card
                                        fieldset={"one"}
                                        decisionID={"parking_unlocks_potential"}
                                        handleDecision={this.props.handleDecision}
                                        decisionCopy={this.props.decisionCopy}
                                        decisions={this.props.decisions}
                                    />

                                    <Card
                                        fieldset={"one"}
                                        decisionID={"dense_and_efficient_living"}
                                        handleDecision={this.props.handleDecision}
                                        decisionCopy={this.props.decisionCopy}
                                        decisions={this.props.decisions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="dark">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-12">
                                <h2><span className="highlight">#2 </span>Multimodal Integration</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 col-sm-12">
                                <h3>Will AVs play nice with bikes, pedestrians, and transit?</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="card-list twoAcross">

                                    <Card
                                        fieldset={"two"}
                                        decisionID={"complementary_to_transit"}
                                        handleDecision={this.props.handleDecision}
                                        decisionCopy={this.props.decisionCopy}
                                        decisions={this.props.decisions}
                                    />

                                    <Card
                                        fieldset={"two"}
                                        decisionID={"mixed_environment"}
                                        handleDecision={this.props.handleDecision}
                                        decisionCopy={this.props.decisionCopy}
                                        decisions={this.props.decisions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-12">
                                <h2><span className="highlight">#3 </span>Ownership Models</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 col-sm-12">
                                <h3>There is broad consensus that that the biggest gains in terms of sustainability, affordability, and mobility will comes from shared ownership of AVs.</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="card-list ownership">
                                    <div className="Card card-hero">
                                        <div className="copy">
                                            <h4>Evaluation Framework</h4>
                                            <p>Examining ownership models as a matrix helps explore future options.</p>
                                        </div>
                                        <div className="square">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th className="mainHeader" colSpan="2">
                                                            Ownership
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th>
                                                            Public
                                                        </th>
                                                        <th>
                                                            Private
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="label mainHeader" rowSpan="2"><span className="vert-txt">Occupancy</span></td>
                                                        <td className="label"><span className="vert-txt">Shared</span></td>
                                                        <td className="squareCell"><span className="dot">&#9675;</span></td>
                                                        <td className="squareCell"><span className="dot">&#9675;</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="label"><span className="vert-txt">Single</span></td>
                                                        <td className="squareCell"><span className="dot">&#9675;</span></td>
                                                        <td className="squareCell"><span className="dot">&#9675;</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="Card">
                                        <h4>{this.props.decisionCopy["autonomous_rapid_transit"].name}</h4>
                                        <div className="info">
                                            <div className="text-left">
                                                <p>{this.props.decisionCopy["autonomous_rapid_transit"].copy}</p>
                                            </div>

                                            <div className="square">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className="squareCell"><span className="dot">&#9679;</span></td>
                                                            <td className="squareCell"><span className="dot">&#9679;</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="squareCell"><span className="dot"></span></td>
                                                            <td className="squareCell"><span className="dot"></span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-bottom">
                                            <Checkbox elements={this.props.decisions}
                                                name="autonomous_rapid_transit"
                                                fieldset="three"
                                                eventHandler={this.props.handleDecision}>
                                                <div className="icon"></div>
                                                <p>
                                                    {this.props.decisionCopy["autonomous_rapid_transit"].subtext}
                                                </p>
                                            </Checkbox>
                                        </div>
                                    </div>

                                    <div className="Card">
                                        <h4>{this.props.decisionCopy["car_sharing"].name}</h4>
                                        <div className="info">
                                            <div className="text-left">
                                                <p>{this.props.decisionCopy["car_sharing"].copy}</p>
                                            </div>

                                            <div className="square">
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <td className="squareCell"><span className="dot">&#9679;</span></td>
                                                        <td className="squareCell"><span className="dot">&#9679;</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="squareCell"><span className="dot"></span></td>
                                                        <td className="squareCell"><span className="dot"></span></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-bottom">
                                            <Checkbox elements={this.props.decisions}
                                                name="car_sharing"
                                                fieldset="three"
                                                eventHandler={this.props.handleDecision}>
                                                <div className="icon"></div>
                                                <p>
                                                    {this.props.decisionCopy["car_sharing"].subtext}
                                                </p>
                                            </Checkbox>
                                        </div>
                                    </div>

                                    <div className="Card">
                                        <h4>{this.props.decisionCopy["fleets"].name}</h4>
                                        <div className="info">
                                            <div className="text-left">
                                                <p>
                                                    {this.props.decisionCopy["fleets"].copy}
                                                </p>
                                            </div>

                                            <div className="square">
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <td className="squareCell"><span className="dot"></span></td>
                                                        <td className="squareCell"><span className="dot">&#9679;</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="squareCell"><span className="dot"></span></td>
                                                        <td className="squareCell"><span className="dot"></span></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-bottom">
                                            <Checkbox elements={this.props.decisions}
                                                name="fleets"
                                                fieldset="three"
                                                eventHandler={this.props.handleDecision}>
                                                <div className="icon"></div>
                                                <p>
                                                    {this.props.decisionCopy["fleets"].subtext}
                                                </p>
                                            </Checkbox>
                                        </div>
                                    </div>

                                    <div className="Card">
                                        <h4>{this.props.decisionCopy["av_single_taxis"].name}</h4>
                                        <div className="info">
                                            <div className="text-left">
                                                <p>
                                                   {this.props.decisionCopy["av_single_taxis"].copy}
                                                </p>
                                            </div>

                                            <div className="square">
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <td className="squareCell"><span className="dot"></span></td>
                                                        <td className="squareCell"><span className="dot"></span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="squareCell"><span className="dot"></span></td>
                                                        <td className="squareCell"><span className="dot">&#9679;</span></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-bottom">
                                            <Checkbox
                                                elements={this.props.decisions}
                                                name="av_single_taxis"
                                                fieldset="three"
                                                eventHandler={this.props.handleDecision}>
                                                <div className="icon"></div>
                                                <p>
                                                    {this.props.decisionCopy["av_single_taxis"].subtext}
                                                </p>
                                            </Checkbox>
                                        </div>
                                    </div>

                                    <div className="Card">
                                        <h4>{this.props.decisionCopy["personal_avs"].name}</h4>
                                        <div className="info">
                                            <div className="text-left">
                                                <p>
                                                    {this.props.decisionCopy["personal_avs"].copy}
                                                </p>
                                            </div>

                                            <div className="square">
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <td className="squareCell"><span className="dot"></span></td>
                                                        <td className="squareCell"><span className="dot"></span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="squareCell"><span className="dot"></span></td>
                                                        <td className="squareCell"><span className="dot">&#9679;</span></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-bottom">
                                            <Checkbox elements={this.props.decisions}
                                                name="personal_avs"
                                                fieldset="three"
                                                eventHandler={this.props.handleDecision}>
                                                <div className="icon"></div>
                                                <p>
                                                    {this.props.decisionCopy["personal_avs"].subtext}
                                                </p>
                                            </Checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="dark">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-12">
                                <h2><span className="highlight">#4 </span>The Public Realm</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 col-sm-12">
                                <h3>How will autonomous mobility support efforts to reclaim the public realm?</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="card-list">

                                    <Card
                                        fieldset={"four"}
                                        decisionID={"coordinated_partnerships"}
                                        handleDecision={this.props.handleDecision}
                                        decisionCopy={this.props.decisionCopy}
                                        decisions={this.props.decisions}
                                    />

                                    <Card
                                        fieldset={"four"}
                                        decisionID={"innovative_roadways"}
                                        handleDecision={this.props.handleDecision}
                                        decisionCopy={this.props.decisionCopy}
                                        decisions={this.props.decisions}
                                    />

                                    <Card
                                        fieldset={"four"}
                                        decisionID={"reclaiming_the_street"}
                                        handleDecision={this.props.handleDecision}
                                        decisionCopy={this.props.decisionCopy}
                                        decisions={this.props.decisions}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-12">
                                <h2><span className="highlight">#5 </span>Social Impact and Equity</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 col-sm-12">
                                <h3>Will the impacts of AV technology on the built realm help mitigate social divides and public health challenges or exacerbate them?</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="card-list fourAcross">

                                    <Card
                                        fieldset={"five"}
                                        decisionID={"safety"}
                                        handleDecision={this.props.handleDecision}
                                        decisionCopy={this.props.decisionCopy}
                                        decisions={this.props.decisions}
                                    />

                                    <Card
                                        fieldset={"five"}
                                        decisionID={"access"}
                                        handleDecision={this.props.handleDecision}
                                        decisionCopy={this.props.decisionCopy}
                                        decisions={this.props.decisions}
                                    />

                                    <Card
                                        fieldset={"five"}
                                        decisionID={"jobs"}
                                        handleDecision={this.props.handleDecision}
                                        decisionCopy={this.props.decisionCopy}
                                        decisions={this.props.decisions}
                                    />

                                    <Card
                                        fieldset={"five"}
                                        decisionID={"data"}
                                        handleDecision={this.props.handleDecision}
                                        decisionCopy={this.props.decisionCopy}
                                        decisions={this.props.decisions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="dark last">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-12 text-center mx-auto">
                                <h2>What does your AV future look like?</h2>
                                <h3>Compare your preferences to those around the world!</h3>

                                <Link to="/results">
                                    <div className="button">
                                        See your score
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

export default Decisions;

