import React, { Component } from 'react';
import L from 'leaflet';

import Checkbox from './../../components/Checkbox';

import './Map.css';

class Map extends Component {
    constructor(props){
        super(props);
        this.state = {
            active: false
        };
        this.randomID = "map-" + Math.random().toString(36).substring(7);
        this.leafletEls = {};
        this.bounds = null;
        this.map = null;
    }
    componentDidMount() {
        // create map
        let mapZoom = 2;
        this.map = L.map(this.randomID, {
            crs: L.CRS.Simple,
            zoomSnap: 0.1,
            zoomDelta: 0.1,
            zoom: mapZoom,
            zoomControl: false,
            dragging: false,
            taps: false,
            closePopupOnClick: false,
            scrollWheelZoom: false,
            doubleClickZoom: false
        });

        let southWest = this.map.unproject([0, this.props.imageDimensions[0]], mapZoom);
        let northEast = this.map.unproject([this.props.imageDimensions[1], 0], mapZoom);
        this.bounds = new L.LatLngBounds(southWest, northEast);
        L.imageOverlay(this.props.imageOverlay, this.bounds).addTo(this.map);
        this.map.fitBounds(this.bounds);


        this.layer = L.layerGroup().addTo(this.map);

        this.props.markers.forEach((marker) => {
            let icon = L.divIcon({
                className: 'map-marker',
                iconSize: null,
                iconAnchor: [25,25],
                keyboard: false,
                popupAnchor: [0, -30],
                html:'<div class="icon">'+this.props.implicationCopy[marker].icon+'</div><div class="arrow" />'
            });

            let popupText = this.props.implicationCopy[marker].copy;

            let popup = L.popup({
                closeButton: false
            }).setContent('<p>'+ popupText +'</p>');

            let point = L.point((this.props.implicationCopy[marker].coords[0]), (this.props.implicationCopy[marker].coords[1]));
            let latlng = this.map.unproject(point, mapZoom);

            let newMarker = L.marker(
                latlng,
                { title: this.props.implicationCopy[marker].name,
                  icon: icon
                }
            ).addTo(this.layer).bindPopup(popup).off('click');

            this.leafletEls[marker] = newMarker;
        });
    }
    componentDidUpdate(){
        if (this.props.filters.length > 0){
            this.props.markers.forEach((marker) => {
                if (this.props.filters.indexOf(this.props.implicationCopy[marker].tag) === -1){
                    L.DomUtil.addClass(this.leafletEls[marker]._icon, 'disabled');
                } else {
                    L.DomUtil.removeClass(this.leafletEls[marker]._icon, 'disabled');
                }
            });
        } else {
            this.props.markers.forEach((marker) => {
                L.DomUtil.removeClass(this.leafletEls[marker]._icon, 'disabled');
            });
        }
    }
    resetView = () => {
        this.map.fitBounds(this.bounds);
    };
    handleCheckbox = (el) => {
        this.props.handleImplication(el);
        this.leafletEls[el].openPopup();
        this.setState({
            active: el
        });
        if (L.DomUtil.hasClass(this.leafletEls[el]._icon, 'selected')){
            L.DomUtil.removeClass(this.leafletEls[el]._icon, 'selected');
        } else {
            L.DomUtil.addClass(this.leafletEls[el]._icon, 'selected');
        }
    };
    handleLabel = (el) => {
        if (this.state.active === el){
            this.leafletEls[el].closePopup();
            this.setState({
                active: null
            });
            this.resetView();
        } else {
            this.leafletEls[el].openPopup();
            this.map.flyTo(L.latLng(this.leafletEls[el].getLatLng()), 3, {
                duration: 1
            });
            this.setState({
                active: el
            });
        }
    };
    render() {
        return (
            <div className="Map">
                <div className={"container"}>
                    <div className="implication-buttons">
                        {this.props.markers.map((implication) =>
                            <div key={implication} className={"implication-button" + ((this.state.active === implication) ? " active" : "") + ((this.props.filters.indexOf(this.props.implicationCopy[implication].tag) === -1 && this.props.filters.length > 0) ? " disabled" : "")}>
                                <p className="implication" onClick={() => this.handleLabel(implication)}>
                                    <span>{this.props.implicationCopy[implication] && this.props.implicationCopy[implication].icon}</span>
                                    {this.props.implicationCopy[implication] && this.props.implicationCopy[implication].name }
                                    <span className="tooltip zoom">
                                        <i className="fas fa-caret-up"/>
                                        Tell me more
                                    </span>
                                </p>
                                <Checkbox elements={this.props.implications}
                                          name={implication}
                                          eventHandler={this.handleCheckbox}>
                                    <div className="icon">
                                        <div className="heart">
                                        </div>
                                        <span className="tooltip like">
                                            <i className="fas fa-caret-up"/>
                                            I like this!
                                        </span>
                                    </div>
                                </Checkbox>
                            </div>
                        )}
                    </div>
                </div>

                <div className="leaflet-map" id={this.randomID}>

                </div>
                <div className={"container"}>
                    <button onClick={this.resetView} className="reset-map">
                        Reset View
                    </button>
                </div>

            </div>
        );
    }
}

export default Map;


