import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import './Footer.css';

class Footer extends Component {
    render() {
        return (
            <footer className="Footer">
                <span>Sponsored by <a href="http://www.sasaki.com" target={"_blank"}>SASAKI</a></span>
                <div className="right">
                    {/*<span><Link to="/about">About</Link></span>*/}
                    <span><a href="mailto:shiftinggears@sasaki.com">Contact</a></span>
                </div>
            </footer>
        );
    }
}

export default Footer;

