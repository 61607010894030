import React, { Component } from 'react';

import {Link} from 'react-router-dom';

import facebook from './../../assets/facebook.svg';
import twitter from './../../assets/twitter.svg';
import instagram from './../../assets/instagram.svg';

import thumbBlack from './../../assets/thumb-black.svg';
import heartBlack from './../../assets/heart-black.svg';
import arrowLeft from './../../assets/arrow-left.svg';
import arrowRight from './../../assets/arrow-right.svg';


import './Sidebar.css';

class Sidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
            sidebarOpen: false
        }
    }
    toggleSidebar = (e) => {
        this.setState({
            sidebarOpen: !this.state.sidebarOpen
        })
    };
    render() {

        let decisionCount = 0;
        Object.keys(this.props.decisions).map(key =>{
            if (this.props.decisions[key]){
                decisionCount++;
            }
        });


        return (
            <div className={"Sidebar " + ((this.state.sidebarOpen) ? 'open ' : '' ) + window.location.href.split("/#/")[1]}>
                <div className="sidebar-tab" onClick={this.toggleSidebar}>
                    <div>
                        {(this.state.sidebarOpen) ? <img src={arrowRight} alt="Open"/> : <img src={arrowLeft} alt="Close"/>}
                    </div>
                    <i className="fas fa-heart"/>
                    <div className="count">{this.props.implications.length}</div>
                    <i className="fas fa-thumbs-up"/>
                    <div className="count">{decisionCount}</div>

                </div>


                    <div className="sidebar-content">
                        <h2 className="mobile">My AV Future</h2>
                        <div className="sidebar-copy">
                            <h2 className="desktop">My AV Future</h2>
                            <p>What do you think the future should look like with autonomous vehicles? Like and interact with elements throughout this website to see your summary here.</p>

                            <div className="dividing-line"></div>

                            <h3>I like these ideas.</h3>
                            {!this.props.implications.length &&
                                <p>You haven't liked anything yet! Engage with the site for your preferences to show up here.</p>
                            }
                            {this.props.implications.length > 0 &&
                            <ul className="implications">
                                {this.props.implications.map((implication) =>
                                    <li key={implication}>
                                        <i className="fas fa-heart"/>
                                        <span>{this.props.implicationCopy[implication].name}</span>
                                    </li>
                                )}
                            </ul>
                            }

                            <div className="dividing-line"></div>

                            <h3>I support these concepts.</h3>
                            {!decisionCount &&
                                <p>You haven't supported anything yet! Engage with the site for your preferences to show up here.</p>
                            }

                            <ul className="decisions">
                                {Object.keys(this.props.decisions).map(key =>
                                    <div key={key}>
                                        {this.props.decisions[key].length > 0 &&
                                        <li key={this.props.decisions[key]}>
                                            <i className="fas fa-thumbs-up"/>
                                            <span>{this.props.decisionCopy[this.props.decisions[key]].name}</span>
                                        </li>}
                                    </div>)
                                }
                            </ul>
                        </div>
                        <div className="sidebar-footer">
                            <Link to="/results" onClick={this.toggleSidebar}><div className="button">View all results</div></Link>
                            <div className="share">
                            <a href="">Share your AV future</a>
                            <a href=""><img src={facebook} alt="Facebook"/></a>
                            <a href=""><img src={twitter} alt="Twitter"/></a>
                            <a href=""><img src={instagram} alt="Instagram"/></a>
                            </div>
                        </div>

                        <div onClick={this.toggleSidebar} className="mobile-close">
                            CLOSE
                        </div>
                    </div>


                <div className="mobile-badge" onClick={this.toggleSidebar}>
                    <h4>My AV Future</h4>
                    <div className={"icons"}>
                        <span className="implications">{this.props.implications.length}</span>
                        <span className="decisions">{decisionCount}</span>
                    </div>
                </div>

            </div>
        );
    }
}

export default Sidebar;

