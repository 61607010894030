import React, { Component } from 'react';

import {Link} from 'react-router-dom';

import './Home.css';

import heartIcon from './../../assets/heart-icon.svg';
import lawGreen from './../../assets/law-green.svg';
import carGreen from './../../assets/car-green.svg';
import gearGreen from './../../assets/gear-green.svg';
import buildingGreen from './../../assets/building-green.svg';
import mapOutline from './../../assets/map-outline.svg';
import signOutline from './../../assets/sign-outline.svg';

class Home extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div className="Home">

                <div className="container">
                    <div className="row">
                        <div className="col-1">
                        </div>
                        <div className="hero col-10 align-self-center">
                            <h1>An Urbanist Take on</h1>
                            <h1 className="highlight">Autonomous Vehicles</h1>
                            <div>
                                <span className="name">by <a href="http://www.sasaki.com" target={"_blank"}>Sasaki</a></span>
                            </div>
                        </div>
                        <div className="col-1">
                        </div>
                    </div>
                </div>

                <div className="callout">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-sm-12 align-self-center text-center">
                                <h2>A team of Sasaki designers undertook a research project in 2017 to examine the future of autonomous vehicles (AV). Explore their findings through this site.</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copy">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-sm-12">
                                <h3>From Car-Serving to Human-Centric</h3>
                                <p>
                                    Cities are currently centered around cars and cater to the needs of roads, parking, and the enormous amount of space that cars take up in our lives. However, with the advent of AVs, we have the potential to transform our cities to become more human-centric, allowing for a future where fleets of AVs can better serve the needs of city dwellers.
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-center list">
                            <div className="col-md-10 col-sm-12">
                                <h3>There are several players currently driving the AV landscape.</h3>
                                <ul className="list-unstyled">
                                    <li>
                                        <div className="icon">
                                            <img src={lawGreen} alt="The Public Sector"/>
                                        </div>
                                        <div className="item-copy">
                                            <h4>The Public Sector</h4>
                                            <p>
                                                It is no secret that federal, state, and municipal governments are ultimately responsible for determining when and how AVs may operate. In a perfect world, legislation represents the majority desire of the people and benefits society at a large scale. On the other hand, this process can take a while to enact due to legislative timelines and varying agendas.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <img src={carGreen} alt="The Tech + Auto Industry"/>
                                        </div>
                                        <div className="item-copy">
                                            <h4>The Tech + Auto Industry</h4>
                                            <p>
                                                The tech and auto industries are inherently involved in the development of autonomous vehicles. The tech companies have primarily focused on networks, remote sensing, and software platforms for autonomous and connected vehicles.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <img src={gearGreen} alt="The Builders"/>
                                        </div>
                                        <div className="item-copy">
                                            <h4>The Builders</h4>
                                            <p>
                                                As the ones responsible for the implementation and construction of much of our built fabric, real estate developers and universities are thinking progressively about building flexibility, the future of parking, and how their campuses can serve as ideal testing grounds for the new technology.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <img src={buildingGreen} alt="The Designers"/>
                                        </div>
                                        <div className="item-copy">
                                            <h4>The Designers</h4>
                                            <p>
                                                As a design firm, Sasaki is not alone in researching and postulating about the anticipated effects of AV technology on urban systems and the built form.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-sm-12 align-self-center text-center">
                                <h2>We want to hear from you!</h2>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-sm-12 align-self-center text-center">
                                <img className="heartIcon" src={heartIcon} alt="Love" />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-7 col-sm-12 text-center bottom-rule">
                                <h3 className="light">Look for the interactive pink icons throughout the site to tell us what concepts you support and which ideas you like!</h3>
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-md-6 col-sm-12 text-center">
                                <div className="explore first">
                                    <p>How will AVs change the physical landscape of our cities, neighborhoods, and towns?</p>
                                    <Link to="/physical">
                                        <div className="card">
                                            <h3>Explore</h3>
                                            <h2>Physical Impacts</h2>
                                            <div className="map-icon">
                                                <img alt="Explore Physical Impacts" src={mapOutline} />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 text-center">
                                <div className="explore">
                                    <p>What important tradeoffs and guideposts will inform how AVs play out in our communities?</p>
                                    <Link to="/decisions">
                                        <div className="card">
                                            <h3>Explore</h3>
                                            <h2>Big Decisions</h2>
                                            <div className="map-icon">
                                                <img alt="Explore Big Decisions" src={signOutline} />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;

